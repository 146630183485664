// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[production]6.1.1-11161(72a45e2abc)-C23/01/2025-09:27:59-B23/01/2025-10:32:39' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[production]6.1.1-11161(72a45e2abc)-C23/01/2025-09:27:59-B23/01/2025-10:32:39",
  branch: "production",
  latestTag: "6.1.1",
  revCount: "11161",
  shortHash: "72a45e2abc",
  longHash: "72a45e2abc0b3c9ea416c6fecca951b886087fdf",
  dateCommit: "23/01/2025-09:27:59",
  dateBuild: "23/01/2025-10:32:39",
  buildType: "Ansible",
  } ;
